import React from 'react'
import PropTypes from 'prop-types'
import { ModalInfo } from '@findep/mf-landings-core';
import { useStaticQuery, graphql } from "gatsby"
import CancelIcon from '@material-ui/icons/Cancel';

function ModalJumio({ errorCode, icon, id, title, autoClose, open, color, onChange, buttonJustify, btnRight, btnRightDisabled, btnRightColor, onClose }) {

    const data = useStaticQuery(graphql`
    query {
        allComponentsJson(filter: {component: {eq: "jumio-app"}}) {
            edges {
              node {
                jumio {
                    display
                    error
                }
              }
            }
          }
      }
  `)

    let displayError = data.allComponentsJson.edges[0].node.jumio.find(item => item.error === errorCode)
    displayError = displayError?.display

    return (
        <ModalInfo
            icon={icon}
            id={id}
            title={title}
            autoClose={autoClose}
            open={open}
            color={color}
            onChange={onChange}
            buttonJustify={buttonJustify}
            btnRight={btnRight}
            btnRightDisabled={btnRightDisabled}
            btnRightColor={btnRightColor}
            onClose={onClose}
            body={<p>{displayError}</p>}
        />
    )
}

ModalJumio.propTypes = {
    icon: PropTypes.elementType,
    id: PropTypes.string,
    title: PropTypes.string,
    autoClose: PropTypes.bool,
    open: PropTypes.bool,
    color: PropTypes.string,
    onChange: PropTypes.func,
    buttonJustify: PropTypes.string,
    btnRight: PropTypes.string,
    btnRightDisabled: PropTypes.bool,
    btnRightColor: PropTypes.string,
    onClose: PropTypes.func,
}

ModalJumio.defaultProps = {
    icon: CancelIcon,
    id: "tu-proceso-modal",
    title: "title modal",
    autoClose: false,
    // open:true,
    color: "primary",
    onChange: () => { },
    buttonJustify: "flex-end",
    btnRight: 'CERRAR',
    btnRightDisabled: false,
    btnRightColor: "primary",
    onClose: () => { },
}

export default ModalJumio