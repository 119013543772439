import { css } from '@emotion/react' 
import React, { useEffect, useState} from 'react'
import CircularProgress from '@material-ui/core/CircularProgress';
import UpdateIcon from '@material-ui/icons/Update';
import { useTheme } from '@findep/microfronts-core'

const maxValue = 100

export default function Progress() {
    const [value, setValue] = useState(0)
    const [timer, setTimer] = useState()

    useEffect(() => {
        const timerVal = setInterval(() => setValue(prevValue => prevValue + 1), 70)
        setTimer(timerVal)

        return () => {
            clearInterval(timer);
        };

    }, [])

    useEffect(() => {
        if(value >= maxValue) {
            clearInterval(timer)
        }

    }, [value])

    const { palette } = useTheme()
    const progressCss = css`
        transform: rotate(320deg) !important;
        color: ${palette.primary.contrastText };
    `

    return (
        value  >= maxValue ? <UpdateIcon css={css`font-size: 30px;`}/> : <CircularProgress thickness={5} size={22} css={progressCss} variant="static" value={value} />
    )
}
